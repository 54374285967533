import {BrowserModule, Title} from '@angular/platform-browser';
import {TitleService} from './services/title.service';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import {AppComponent} from './app.component';
import {ElectricianAppsComponent} from './electrician-apps/electrician-apps.component';
import {SponsorshipComponent} from './sponsorship/sponsorship.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {QuotingSoftwareComponent} from './quoting-software/quoting-software.component';
import {QuotationComponent} from './quotation/quotation.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {RecrutementComponent} from './recrutement/recrutement.component';
import { FaqComponent } from './faq/faq.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appRoutes: Routes = [
  {
    path: 'electrician_apps_for_android',
    component: ElectricianAppsComponent,
    data: {title: "Energy Droid | electrician apps for android"}
  },
  {
    path: 'application_electricite_android',
    component: ElectricianAppsComponent,
    data: {title: "Energy Droid | application electricite android"}
  },
  {
    path: 'app_para_electricistas_android',
    component: ElectricianAppsComponent,
    data: {title: "Energy Droid | app para electricistas android"}
  },
  {
    path: 'electrical_quoting_software',
    component: QuotingSoftwareComponent,
    data: {title: "Energy Droid | electrical quoting software"}
  },
  {
    path: 'logiciel_devis_facture_electricite',
    component: QuotingSoftwareComponent,
    data: {title: "Energy Droid | logiciel devis facture electricite"}
  },
  {
    path: 'software_electricistas',
    component: QuotingSoftwareComponent,
    data: {title: "Energy Droid | software electricistas"}
  },
  {
    path: 'electrical_quotation',
    component: QuotationComponent,
    data: {title: "Energy Droid | electrical quotation"}
  },
  {
    path: 'devis_electricite',
    component: QuotationComponent,
    data: {title: "Energy Droid | devis electricite"}
  },
  {
    path: 'presupuesto_de_electricidad',
    component: QuotationComponent,
    data: {title: "Energy Droid | presupuesto de electricidad"}
  },
  {
    path: 'electrical_invoice',
    component: InvoiceComponent,
    data: {title: "Energy Droid | electrical invoice"}
  },
  {
    path: 'facture_electricite',
    component: InvoiceComponent,
    data: {title: "Energy Droid | facture electricite"}
  },
  {
    path: 'factura_de_electricidad',
    component: InvoiceComponent,
    data: {title: "Energy Droid | factura de electricidad"}
  },
  {
    path: 'sponsorship',
    component: SponsorshipComponent,
    data: {title: "Energy Droid | sponsorship"}
  },
  {
    path: 'parrainage',
    component: SponsorshipComponent,
    data: {title: "Energy Droid | parrainage"}
  },
  {
    path: 'patrocinio',
    component: SponsorshipComponent,
    data: {title: "Energy Droid | patrocinio"}
  },
  {
    path: 'recruitment',
    component: RecrutementComponent,
    data: {title: "Energy Droid | recruitment"}
  },
  {
    path: 'recrutement',
    component: RecrutementComponent,
    data: {title: "Energy Droid | recrutement"}
  },
  {
    path: 'reclutamiento',
    component: RecrutementComponent,
    data: {title: "Energy Droid | reclutamiento"}
  },
  {
    path: 'privacy_policy',
    component: PrivacyPolicyComponent,
    data: {title: "Energy Droid | Privacy Policy"}
  },
  {
    path: 'politique_de_confidentialite',
    component: PrivacyPolicyComponent,
    data: {title: "Energy Droid | Politique de confidentialité"}
  }, {
    path: 'politica_de_privacidad',
    component: PrivacyPolicyComponent,
    data: {title: "Energy Droid | Política de privacidad"}
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {title: "Energy Droid | FAQ"}
  },
  {
    path: 'questions_frequentes',
    component: FaqComponent,
    data: {title: "Energy Droid | Questions fréquentes"}
  }, {
    path: 'preguntas_frecuentes',
    component: FaqComponent,
    data: {title: "Energy Droid | Preguntas frecuentes"}
  },
  {
    path: '',
    component: ElectricianAppsComponent,
    data: {title: "Energy Droid "}
  },
  {
    path: 'page_not_found',
    component: NotFoundComponent,
    data: {title: "Energy Droid | page_not_found Page Titre"}
  },
  {
    path: '**',
    redirectTo: '/page_not_found'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ElectricianAppsComponent,
    SponsorshipComponent,
    NotFoundComponent,
    QuotingSoftwareComponent,
    QuotationComponent,
    InvoiceComponent,
    PrivacyPolicyComponent,
    RecrutementComponent,
    FaqComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'}),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(titleService: TitleService) {
    titleService.init();
  }
}
