import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  appName = 'Energy Droid';

 constructor(private translate: TranslateService, private titleService: Title) {
   //navigator.userLanguage => IE
   //var navigateurLang = navigator.language || navigator.userLanguage;
   var navigateurLang = navigator.language;
   if (navigateurLang) {
     let tableauCodeLangue = navigateurLang.split("-");

     if ("fr" === tableauCodeLangue[0]) {
       translate.setDefaultLang("fr");
     } else if ("es" === tableauCodeLangue[0]) {
       translate.setDefaultLang("es");
     } else {
       translate.setDefaultLang("en");
     }

   } else {
     translate.setDefaultLang("en");
   }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

 public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
