import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-quoting-software',
  templateUrl: './quoting-software.component.html',
  styleUrls: ['./quoting-software.component.scss']
})
export class QuotingSoftwareComponent  {

   constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'electrical quoting software'},
        {name: 'description', lang: 'fr', content: 'logiciel devis facture electricite'},
        {name: 'description', lang: 'es', content: 'software electricistas'},
        {name: 'keywords', lang: 'en', content: 'electrical quoting software,electrical estimating software,electrician invoice software,best electrical estimating software,electrician business software,electrical accounting software,electrical management software'},
        {name: 'keywords', lang: 'fr', content: 'logiciel devis facture electricite,logiciel devis electricien,logiciel devis electricite,logiciel gestion electricien,logiciel pour electricien,logiciel electricité'},
        {name: 'keywords', lang: 'es', content: 'software electricistas,software para instaladores electricistas,programa para hacer facturas,software para electricistas,software instalaciones electricas,programas para electricistas,programa para hacer instalaciones electricas,programa para instalaciones electricas,programa de instalaciones electricas,programa de electricidad,software de electricidad,software de instalaciones electricas'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

}
