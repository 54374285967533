import { Component} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-electrician-apps',
  templateUrl: './electrician-apps.component.html',
  styleUrls: ['./electrician-apps.component.scss']
})
export class ElectricianAppsComponent {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Energy Droid is an electrician app for android'},
        {name: 'description', lang: 'fr', content: 'Energy Droid est une application electricite pour android'},
        {name: 'description', lang: 'es', content: 'Energy Droid es una app para electricistas android'},
        {name: 'author', content: 'Energy Droid team- Sakidev'},
        {name: 'keywords', lang: 'en', content: 'electrician apps for android,electrical calculation software for android,electrician apps,electrical apps,apps electrical,best apps for electricians,best apps for electrical contractors,electrical engineering apps for android,electrical calculations app,electrical estimating apps,electrician application,mobile electrician app, Energy Droid'},
        {name: 'keywords', lang: 'fr', content: 'application electricite android,application Android electricite,application pour electricien, Energy Droid'},
        {name: 'keywords', lang: 'es', content: 'app para electricistas android,app electricidad android,aplicaciones para electricistas,aplicaciones electricas para android,app para electricistas android,app electricidad,app de electricidad, Energy Droid'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {name: 'format-detection', content: 'telephone=no'},
        {name: 'format-detection', content: 'address=no'}
      ]);

      if (route.snapshot.url[0] && route.snapshot.url[0].path){
          this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
      }

    }


}
