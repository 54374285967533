import { Component} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';


@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent  {

   constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Electrical quotation'},
        {name: 'description', lang: 'fr', content: 'Devis electricite'},
        {name: 'description', lang: 'es', content: 'presupuesto de electricidad'},
        {name: 'keywords', lang: 'en', content: 'electrical quotation,electrician proposal,electricity quotes,electrician quotes,quote for electrical work,electrical work quotation'},
        {name: 'keywords', lang: 'fr', content: 'Devis electricite,devis electricien,devis travaux electricité,electricien devis,Devis electricien'},
        {name: 'keywords', lang: 'es', content: 'presupuesto de electricidad,presupuesto instalacion electrica'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }



}
